interface TextStyle {
	fontSize: string
	lineHeight: string
}

interface TextStyles {
	bold: TextStyle
	regular: TextStyle
}

interface HeadingStyles {
	h1: TextStyle
	h2: TextStyle
	h3: TextStyle
	h4: TextStyle
	h5: TextStyle
	h6: TextStyle
}

interface BodyStyles {
	largeText: TextStyles
	mediumText: TextStyles
	normalText: TextStyles
	smallText: TextStyles
}

interface Typography {
	headings: HeadingStyles
	body: BodyStyles
}

interface TypographySystem {
	typography: Typography
}

export const typographySystem: TypographySystem = {
	typography: {
		headings: {
			h1: {
				fontSize: '3.5rem' /* 56px / 16px */,
				lineHeight: '3.85rem' /* 61.6px / 16px */,
			},
			h2: {
				fontSize: '3rem' /* 48px / 16px */,
				lineHeight: '3.3rem' /* 52.8px / 16px */,
			},
			h3: {
				fontSize: '2.5rem' /* 40px / 16px */,
				lineHeight: '2.75rem' /* 44px / 16px */,
			},
			h4: {
				fontSize: '2rem' /* 32px / 16px */,
				lineHeight: '2.2rem' /* 35.2px / 16px */,
			},
			h5: {
				fontSize: '1.5rem' /* 24px / 16px */,
				lineHeight: '1.65rem' /* 26.4px / 16px */,
			},
			h6: {
				fontSize: '1.25rem' /* 20px / 16px */,
				lineHeight: '1.375rem' /* 22px / 16px */,
			},
		},
		body: {
			largeText: {
				bold: {
					fontSize: '1.25rem' /* 20px / 16px */,
					lineHeight: '1.75rem' /* 28px / 16px */,
				},
				regular: {
					fontSize: '1.25rem' /* 20px / 16px */,
					lineHeight: '1.75rem' /* 28px / 16px */,
				},
			},
			mediumText: {
				bold: {
					fontSize: '1.125rem' /* 18px / 16px */,
					lineHeight: '1.575rem' /* 25.2px / 16px */,
				},
				regular: {
					fontSize: '1rem' /* 16px / 16px */,
					lineHeight: '1.4rem' /* 22.4px / 16px */,
				},
			},
			normalText: {
				bold: {
					fontSize: '1rem' /* 16px / 16px */,
					lineHeight: '1.4rem' /* 22.4px / 16px */,
				},
				regular: {
					fontSize: '1rem' /* 16px / 16px */,
					lineHeight: '1.4rem' /* 22.4px / 16px */,
				},
			},
			smallText: {
				bold: {
					fontSize: '0.875rem' /* 14px / 16px */,
					lineHeight: '1.225rem' /* 19.6px / 16px */,
				},
				regular: {
					fontSize: '0.875rem' /* 14px / 16px */,
					lineHeight: '1.225rem' /* 19.6px / 16px */,
				},
			},
		},
	},
}

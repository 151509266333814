import { type Config } from 'tailwindcss'
import { typographySystem } from '#app/styles/typographySystem.ts'

const { headings, body } = typographySystem.typography

export const extendedTheme = {
	colors: {
		border: 'hsl(var(--border))',
		input: {
			DEFAULT: 'hsl(var(--input))',
			invalid: 'hsl(var(--input-invalid))',
		},
		ring: {
			DEFAULT: 'hsl(var(--ring))',
			invalid: 'hsl(var(--foreground-destructive))',
		},
		background: 'hsl(var(--background))',
		foreground: {
			DEFAULT: 'hsl(var(--foreground))',
			destructive: 'hsl(var(--foreground-destructive))',
		},
		destructive: {
			DEFAULT: 'hsl(var(--destructive))',
			foreground: 'hsl(var(--destructive-foreground))',
		},
		muted: {
			DEFAULT: 'hsl(var(--muted))',
			foreground: 'hsl(var(--muted-foreground))',
		},
		accent: {
			DEFAULT: 'hsl(var(--accent))',
			foreground: 'hsl(var(--accent-foreground))',
		},
		popover: {
			DEFAULT: 'hsl(var(--popover))',
			foreground: 'hsl(var(--popover-foreground))',
		},
		card: {
			DEFAULT: 'hsl(var(--card))',
			foreground: 'hsl(var(--card-foreground))',
		},
		primary: {
			DEFAULT: 'hsl(var(--primary-default))',
			foreground: 'hsl(var(--primary-foreground))',
			'200': 'hsl(var(--primary-200))',
			'300': 'hsl(var(--primary-300))',
			'400': 'hsl(var(--primary-400))',
			'500': 'hsl(var(--primary-500))',
		},
		// Secondary color system
		secondary: {
			DEFAULT: 'hsl(var(--secondary-default))',
			foreground: 'hsl(var(--secondary-foreground))',
			'200': 'hsl(var(--secondary-200))',
			'300': 'hsl(var(--secondary-300))',
			'400': 'hsl(var(--secondary-400))',
			'500': 'hsl(var(--secondary-500))',
		},
		// State color system
		info: {
			'300': 'hsl(var(--info-300))',
		},
		success: {
			'300': 'hsl(var(--success-300))',
		},
		warning: {
			'300': 'hsl(var(--warning-300))',
		},
		error: {
			'300': 'hsl(var(--error-300))',
		},
		// Black color system
		black: {
			DEFAULT: 'hsl(var(--black-default))',
			'100': 'hsl(var(--black-100))',
			'200': 'hsl(var(--black-200))',
			'300': 'hsl(var(--black-300))',
			white: {
				'100': 'hsl(var(--white-100))',
				'200': 'hsl(var(--white-200))',
			},
		},
		// Grey color system
		grey: {
			DEFAULT: 'hsl(var(--grey-default))',
			'200': 'hsl(var(--grey-200))',
			'300': 'hsl(var(--grey-300))',
			'400': 'hsl(var(--grey-400))',
			'500': 'hsl(var(--grey-500))',
			'600': 'hsl(var(--grey-600))',
		},
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
		'4xl': '2rem',
		'5xl': '2.5rem',
		'6xl': '3rem',
		'7xl': '3.5rem',
	},
	backdropFilter: ['responsive'],
	fontSize: {
		// 1rem = 16px
		/** 80px size / 84px high / bold */
		mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
		/** 56px size / 62px high / bold */
		h1: [
			headings.h1.fontSize,
			{ lineHeight: headings.h1.lineHeight, fontWeight: '700' },
		],
		h2: [
			headings.h2.fontSize,
			{ lineHeight: headings.h2.lineHeight, fontWeight: '700' },
		],
		h3: [
			headings.h3.fontSize,
			{ lineHeight: headings.h3.lineHeight, fontWeight: '700' },
		],
		h4: [
			headings.h4.fontSize,
			{ lineHeight: headings.h4.lineHeight, fontWeight: '700' },
		],
		h5: [
			headings.h5.fontSize,
			{ lineHeight: headings.h5.lineHeight, fontWeight: '700' },
		],
		h6: [
			headings.h6.fontSize,
			{ lineHeight: headings.h6.lineHeight, fontWeight: '700' },
		],

		// Body - largeText
		'body-lg': [
			body.largeText.regular.fontSize,
			{ lineHeight: body.largeText.regular.lineHeight },
		],
		'body-lg-bold': [
			body.largeText.bold.fontSize,
			{ lineHeight: body.largeText.bold.lineHeight, fontWeight: '700' },
		],

		// Body - mediumText
		'body-md': [
			body.mediumText.regular.fontSize,
			{ lineHeight: body.mediumText.regular.lineHeight },
		],
		'body-md-bold': [
			body.mediumText.bold.fontSize,
			{ lineHeight: body.mediumText.bold.lineHeight, fontWeight: '700' },
		],

		// Body - normalText
		'body-sm': [
			body.normalText.regular.fontSize,
			{ lineHeight: body.normalText.regular.lineHeight },
		],
		'body-sm-bold': [
			body.normalText.bold.fontSize,
			{ lineHeight: body.normalText.bold.lineHeight, fontWeight: '700' },
		],

		// Body - smallText
		'body-xs': [
			body.smallText.regular.fontSize,
			{ lineHeight: body.smallText.regular.lineHeight },
		],
		'body-xs-bold': [
			body.smallText.bold.fontSize,
			{ lineHeight: body.smallText.bold.lineHeight, fontWeight: '700' },
		],

		/** 18px size / 24px high / semibold */
		caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
		/** 12px size / 16px high / bold */
		button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
	},
	keyframes: {
		'accordion-down': {
			from: { height: '0' },
			to: { height: 'var(--radix-accordion-content-height)' },
		},
		'accordion-up': {
			from: { height: 'var(--radix-accordion-content-height)' },
			to: { height: '0' },
		},
	},
	animation: {
		'accordion-down': 'accordion-down 0.2s ease-out',
		'accordion-up': 'accordion-up 0.2s ease-out',
	},
} satisfies Config['theme']
